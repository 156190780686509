
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/
require('../../../../node_modules/angular');

angular.module('addo', []).controller('PageController', ['$scope','$http',function($scope, $http){

    let endpoint = "https://convert.addosolutions.com/conversion";
    if(location.href.includes("localhost")) endpoint="http://localhost:3333/conversion";


    this.form = {
        activity:[],
        modalClick: {},
        preferred_contact: "call",
        poo_bear:""
    }
    this.applicationUrl = false;

    window.pageApp = this;

    this.start = (what,e)=>{
        console.log({what,e});
        this.form.modalClick = {
            name: what,
            text: e.target.innerText
        }
        $("#modal-get-started").modal('show');
    }

    this.applyForm = (url,e)=>{
        console.log({url,e});
        this.applicationUrl = url;
        $("#apply-form-dialog").modal('show');
        try{$scope.$apply()}catch(e){console.error(e)}
    }

    this.submit = ()=>{
        let success = ()=>{
            $("#modal-get-started").modal('hide');
            $("#modal-sent").modal('show');
        }
        this.submitting = true;
        $http({
            method: "POST",
            url: endpoint,
            data: {
                "endpoint": "addosolutions",
                "method": "webform",
                "data": this.form,
                "path": `${location.href}/${this.form.modalClick.what}`,
                "pathName": this.form.modalClick.text,
                "recaptcha": "__unittest",
                "tracking": [
                    {
                        "date": (new Date()).toString(),
                        "url": location.href,
                    }
                ]
            },
            success: (e,message)=>{
            },
            dataType: 'json'
        }).then((message)=>{
            console.log({message});
            success();
            gtag('event', 'form-submit', {
                form: JSON.stringify(this.form)
            });



            gtag('event', 'conversion', {
                'send_to': 'AW-16845802215/IyJrCJ_zwZkaEOeV2uA-',
                'value': 25.0,
                'currency': 'USD'
            });
            window.location.hash = 'thanks';


        }).catch((e)=>{
            alert(e.message);
        }).finally(()=>{
            this.submitting = false;
        })

        //success();
    }
}])


angular.bootstrap(document, ['addo']);

$(function() {



});
